import { Link } from 'gatsby'
import React from 'react'
import Logo from "../../images/factory-cat-nav-logo.png"
import PartsIcon from "../individualProductPage/images/parts-manual-40h.jpg"
import OperatorIcon from "../individualProductPage/images/operator-manual-40h.jpg"
import BrochureIcon from "../individualProductPage/images/brochure-icon-40h.jpg"
import TechSpecIcon from "../individualProductPage/images/tech-specs-icon-40h.jpg"
import RideOnSweeperIcon from "../../images/tr-ride-on-sweeper.png"
import WalkBehindIcon from "../../images/walkie-icon.jpg"
import RiderIcon from "../../images/rider-icon.jpg"
import Icon34 from "../../images/34-walk-behind-sweeper.png"
import { MdAddCall } from 'react-icons/md'

import '../../styles/global.scss'
export default function DesktopNav() {
    return (
        <nav className="desktop-nav">
            <div>
                <img src={Logo} alt="" />
            </div>
            <div>
                <ul>
                <li><Link to="/">Home</Link></li>
                <li className="desk-products"><Link to="/">Products <i className="fas fa-sort-down down-arrow"></i></Link>
                <ul className="dropdown-desk">
                <li><Link to="/walk-behind-floor-scrubbers"><img src={WalkBehindIcon} alt="WalkBehindIcon" /> Walk Behind Scrubbers</Link></li>
                <li><Link to="/walk-behind-floor-sweepers"><img src={Icon34} alt="Icon34" /> Walk Behind Sweepers</Link></li>
                <li><Link to="/ride-on-floor-scrubbers"><img src={RiderIcon} alt="RiderIcon" /> Ride on Scrubbers</Link></li>
                <li><Link to="/ride-on-sweepers"><img src={RideOnSweeperIcon} alt="RideOnSweeperIcon" /> Ride on Sweepers</Link></li>
                <li><Link to="/ride-on-floor-scrubbers/xr"><img src={RiderIcon} alt="RiderIcon" /> Sweeper-Scrubbers</Link></li>
                </ul>
                </li>
                <li className="desk-support"><Link to="/">Support <i className="fas fa-sort-down down-arrow"></i></Link>
                    <ul className="dropdown-desk">
                    <li><Link to="/serial"><i className="fas fa-search media-icon" /> Serial Numbers</Link></li>
                    <li><Link to="/machine-registration"><i className="fas fa-id-card-alt media-icon" /> Machine Registration</Link></li>
                    <li><Link to="/manual-downloads"><img src={BrochureIcon} alt="brochure-icon" /> Brochures</Link></li>
                    <li><Link to="/manual-downloads"><img src={TechSpecIcon} /> Tech Specs</Link></li>
                    <li><Link to="/manual-downloads"><img src={OperatorIcon} alt="op-icon" /> Operator Manuals</Link></li>
                    <li><Link to="/manual-downloads"><img src={PartsIcon} alt="parts" /> Parts Manuals</Link></li>
                    <li><Link to="/contact"><i className="fas fa-phone media-icon" /> Contact Us</Link></li>
                    <li><Link to="/about"><i className="fas fa-address-card media-icon" /> About Us</Link></li>
                    </ul>
                </li>
                <li className="desk-media"><Link to="/">Media <i className="fas fa-sort-down down-arrow"></i></Link>
                <ul className="dropdown-desk">
                <li><Link to="/images"><i className="far fa-images media-icon" /> Images</Link></li>
                <li><Link to="/videos"><i className="fas fa-video media-icon" /> Videos</Link></li>
                <li><Link to="/applications"><i className="fas fa-image media-icon" /> Applications</Link></li>
                </ul>
                </li>
                <li><a href="https://www.tomcatequip.com/shop/" target="_blank">Parts Shop</a></li>
                <li><a href="http://www.portal.rpscorporation.com/" className="login-button" target="_blank">Login</a></li>
                <li><a href="tel:2626813583"><MdAddCall /> 1 262-681-3583</a></li>
                </ul>
            </div>
        </nav>
    )
}
