import React, { useState} from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { MdLogin, MdSearch, MdCreate, MdAddCall } from 'react-icons/md'
import './nav.scss'
// import PartsIcon from "../individualProductPage/images/parts-manual-40h.jpg"
// import OperatorIcon from "../individualProductPage/images/operator-manual-40h.jpg"
// import BrochureIcon from "../individualProductPage/images/brochure-icon-40h.jpg"
// import TechSpecIcon from "../individualProductPage/images/tech-specs-icon-40h.jpg"

const MobileNavStyles = styled.div`
  display: grid;
  width: 350px;
  background-color: rgba(0, 0, 0, 0.8);
  border-right-style: solid;
  border-bottom-style: solid;
  border-color: #ed2024;
  /* border-color: black; */
  border-width: 2px;
  /* border-bottom-right-radius: 90px; */
  color: white;
  font-size: 1.5em;

  ul {
    margin: 0;
    list-style-type: none;
    border-bottom: 1px solid #ed2024;

    li {
      padding: 1rem;
    }
  }

  select {
    padding: 0;
    margin: 0;
    font-size: 1em;
    border: none;
    background: transparent;
    color: white;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: "";
  }
`

const CallUsButton = styled.button`
  background-color: red;
  border: none;
  color: white;
  text-align: center;
  padding: 0.5rem;
  font-size: 1em;
  margin: 1.5rem;
`

const MobileNavigation = () => {
  const [productsMenu, setProductsMenu] = useState(false)
  const [supportMenu, setSupportMenu] = useState(false)
  const [mediaMenu, setMediaMenu] = useState(false)
  function toggleProductsMenu() {
    if(productsMenu) {
      setProductsMenu(!productsMenu)
      } else {
      setProductsMenu(!productsMenu)
      }
  }
  function toggleSupportMenu() {
    if(productsMenu) {
      setSupportMenu(!supportMenu)
      } else {
        setSupportMenu(!supportMenu)
      }
  }
  function toggleMediaMenu() {
    if(mediaMenu) {
      setMediaMenu(!mediaMenu)
      } else {
        setMediaMenu(!mediaMenu)
      }
  }
  return (
    <div>
    <ul>
      <li><Link to="/">Home</Link></li>
      <li><button className="link" onClick={toggleProductsMenu}>Products</button></li>
    </ul>
      <ul className={productsMenu ? "productions-dropdown-menu show" : "productions-dropdown-menu hide" }>
        <li className="subMenu"><Link to="/walk-behind-floor-scrubbers">Walk Behind Scrubbers</Link></li>
        <li className="subMenu"><Link to="/walk-behind-floor-sweepers">Walk Behind Sweepers</Link></li>
        <li className="subMenu"><Link to="/ride-on-floor-scrubbers">Ride on Scrubbers</Link></li>
        <li className="subMenu"><Link to="/ride-on-sweepers">Ride on Sweepers</Link></li>
        <li className="subMenu"><Link to="/ride-on-floor-scrubbers/xr">Sweeper-Scrubbers</Link></li>
      </ul>
    <ul>
      <li><button className="link" onClick={toggleSupportMenu}>Support</button></li>
    </ul>
    <ul className={supportMenu ? "support-dropdown-menu show" : "productions-dropdown-menu hide" }>
        <li className="subMenu"><Link to="/manual-downloads">Downloads</Link></li>
        <li className="subMenu"><Link to="/contact">Contact Us</Link></li>
      </ul>
    <ul>
      <li><button className="link" onClick={toggleMediaMenu}>Media</button></li>
      </ul>
      <ul className={mediaMenu ? "media-dropdown-menu show" : "productions-dropdown-menu hide" }>
        <li className="subMenu"><Link to="/images">Gallery</Link></li>
        <li className="subMenu"><Link to="/videos">Videos</Link></li>
        <li className="subMenu"><Link to="/applications">Applications</Link></li>
      </ul>
      <div className="subdrop-menu">
        <ul>
        <li><a href="http://www.portal.rpscorporation.com/" target="_blank"><MdLogin />Login</a></li>
        <li><a href="https://www.tomcatequip.com/shop/" target="_blank"><MdLogin />Parts Shop</a></li>
          <li><Link to="/serial"><MdSearch />Serial Number Search</Link></li>
          <li><Link to="/machine-registration"><MdCreate />Machine Registration</Link></li>
          <li><a href="tel:2626813583"><MdAddCall />Call Us | 262-681-3583</a></li>
        </ul>
      </div>
    </div>
  )
  // const handleSelect = e => {
  //   window.location = e.target.value
  // }
  // return (
  //   <MobileNavStyles>
  //     <ul>
  //       <li>
  //         <Link style={{ color: "white", textDecoration: "none" }} to="/">
  //           Home
  //         </Link>
  //       </li>
  //       <li>
  //         <select
  //           style={{ backgroundColor: "black" }}
  //           onChange={handleSelect}
  //           id="products"
  //         >
  //           <option disabled selected value="0">
  //             Products
  //           </option>
  //           <option value="/walk-behind-floor-scrubbers">
  //             Walk Behind Scrubbers
  //           </option>
  //           <option value="/walk-behind-floor-sweepers">
  //             Walk Behind Sweepers
  //           </option>
  //           <option value="/ride-on-floor-scrubbers">Ride On Scrubbers</option>
  //           <option value="/ride-on-sweepers">Ride On Sweepers</option>
  //           <option value="sweeper-scrubbers">Sweeper-Scrubber</option>
  //         </select>
  //       </li>
  //       <li>
  //         <select onChange={handleSelect} name="support">
  //           <option disabled selected value="0">
  //             Support
  //           </option>
  //           <option value="/manual-downloads">Downloads</option>
  //           <option value="/contact">Contact</option>
  //         </select>
  //       </li>
  //       <li>
  //         <select onChange={handleSelect} id="products">
  //           <option disabled selected value="0">
  //             Media
  //           </option>
  //           <option value="/images">Images</option>
  //           <option value="/videos">Videos</option>
  //           <option value="/applications">Applications</option>
  //         </select>
  //       </li>
  //     </ul>
  //     <div
  //       style={{ padding: "1rem", marginLeft: "2.3rem" }}
  //       className="mobile-login"
  //     >
  //       <a
  //         style={{ textDecoration: "none", color: "white" }}
  //         href="http://www.portal.rpscorporation.com/"
  //         target="_blank"
  //         rel="noreferrer"
  //       >
  //         <i className="far fa-user" /> Login
  //       </a>
  //       <br />
  //       <Link style={{ textDecoration: "none", color: "white" }} to="/serial">
  //         {/* <i className="fas fa-search" />  */}
  //         Serial No. Search
  //       </Link>
  //       <br />
  //       <Link
  //         style={{ textDecoration: "none", color: "white" }}
  //         to="/machine-registration"
  //       >
  //         {/* <i className="fas fa-search" />  */}
  //         Machine Registration
  //       </Link>
  //       <br />
  //       {/* <a
  //         style={{ textDecoration: "none", color: "white" }}
  //         href="https://www.factorycat.com/shop/"
  //         target="_blank"
  //       >
  //         <i className="fas fa-shopping-cart"></i> Shop
  //       </a> */}
  //       {/* <br /> */}
  //       <a
  //         style={{ textDecoration: "none", color: "white" }}
  //         href="tel:2626813583"
  //         target="_blank"
  //         rel="noreferrer"
  //       >
  //         <i className="fas fa-phone"></i> Call Us
  //       </a>
  //     </div>

  //     {/* <CallUsButton>
  //       <i className="fas fa-phone"> Call Us</i>
  //     </CallUsButton> */}
  //   </MobileNavStyles>
  // )
}

export default MobileNavigation
