import React from "react"
import { Link } from "gatsby"
import './footer.scss'

const Footer = () => {
  return (
    <footer>
        <ul>
          <li className="heading"><h3>Products</h3></li>
          <li><Link to="/walk-behind-floor-scrubbers"></Link></li>
          <li><Link to="/ride-on-floor-scrubbers">Ride On Scrubbers</Link></li>
          <li><Link to="/walk-behind-floor-sweepers">Walk Behind Sweepers</Link></li>
          <li><Link to="/ride-on-sweepers">Ride On Sweepers</Link></li>
          <li><Link to="/ride-on-floor-scrubbers/xr">Sweeper Scrubbers</Link></li>
        </ul>
        <ul>
          <li className="heading"><h3>Support</h3></li>
          <li><Link to="/contact">Contact Us</Link></li>
          <li><Link to="/serial"> Serial Number Lookup</Link></li>
          <li><Link to="/machine-registration">Machine Registration</Link></li>
        </ul>
        <ul>
        <li className="heading"><h3>About FactoryCat</h3></li>
        <li><Link to="/about">About Us</Link></li>
        </ul>
        <ul>
          <li className="heading"><h3>Customer Service</h3></li>
          <li>1-262-681-3583</li>
        </ul>
        <ul>
          <li className="heading"><h3>Connect with Us</h3></li>
          <li>
            <a
              href="https://www.facebook.com/FactoryCatEquip"
              target="_blank"
              rel="noreferrer"
            >
              <i
                style={{ color: "#4267B2" }}
                className="fab fa-facebook-square"
              >
                {" "}
                <span
                  style={{
                    color: "white",
                    fontFamily: "Sans-Serif",
                    fontSize: ".8em",
                  }}
                >
                  {" "}
                  Facebook
                </span>
              </i>
            </a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/factorycat.equipment/"
              target="_blank"
              rel="noreferrer"
            >
              <i style={{ color: "#833AB4" }} className="fab fa-instagram">
                {" "}
                <span
                  style={{
                    color: "white",
                    fontFamily: "Sans-Serif",
                    fontSize: ".8em",
                  }}
                >
                  {" "}
                  Instagram
                </span>
              </i>
            </a>
          </li>
          <li>
            <a
              href="https://www.linkedin.com/company/factorycat/"
              target="_blank"
              rel="noreferrer"
            >
              <i style={{ color: "#2867b2" }} className="fab fa-linkedin">
                {" "}
                <span
                  style={{
                    color: "white",
                    fontFamily: "Sans-Serif",
                    fontSize: ".8em",
                  }}
                >
                  {" "}
                  LinkedIn
                </span>
              </i>
            </a>
          </li>
          <li>
            <a href="https://twitter.com/FactoryCatEquip" target="_blank">
              <i style={{ color: "#1DA1F2" }} className="fab fa-twitter-square">
                {" "}
                <span
                  style={{
                    color: "white",
                    fontFamily: "Sans-Serif",
                    fontSize: ".8em",
                  }}
                >
                  {" "}
                  Twitter
                </span>
              </i>
            </a>
          </li>
          <li>
            <a
              href="https://www.youtube.com/channel/UCazDAPmSElXUXy-5RV-vt1g"
              target="_blank"
              rel="noreferrer"
            >
              <i style={{ color: "#FF0000" }} className="fab fa-youtube-square">
                {" "}
                <span
                  style={{
                    color: "white",
                    fontFamily: "Sans-Serif",
                    fontSize: ".8em",
                  }}
                >
                  {" "}
                  You Tube
                </span>
              </i>
            </a>
          </li>
        </ul>
    </footer>
  )
}

export default Footer
