import React, { useState } from "react"
import { GiHamburgerMenu, GiShoppingCart,  } from 'react-icons/gi'
import { MdClose } from 'react-icons/md'
import DesktopNav from './DesktopNav'
import Logo from "../../images/factory-cat-nav-logo.png"
import MobileNavigation from "./MobileNavigation"

const Navigation = () => {
  const [mobileMenu, setMobileMenu] = useState(false)

  function toggleMobileNav() {
    if(mobileMenu) {
      setMobileMenu(false)
    } else if (!mobileMenu) {
      setMobileMenu(true)
    }
  }

  return (
    <>
    <nav className="mobile-nav">
      <div>{mobileMenu ?  <MdClose onClick={toggleMobileNav}/> : <GiHamburgerMenu onClick={toggleMobileNav}/> }</div>
      <div><img src={Logo} alt="" /></div>
      {/* <div><GiShoppingCart /></div> */}
    </nav>
    <DesktopNav />
    <div className={mobileMenu ? "mobile-dropdown-menu show" : "mobile-dropdown-menu hide" }>
        <MobileNavigation />
    </div>

      </>
  )
}

export default Navigation
